import { __decorate } from "tslib";
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule, ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
// Library imports
import { Ng2FileSizeModule } from 'ng2-file-size';
import { FileUploadModule } from 'ng2-file-upload';
import { MetaModule } from 'ng2-meta';
import { CountdownModule } from 'ngx-countdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule, CarouselModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AutosizeModule } from 'ngx-autosize';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgOtpInputModule } from 'ng-otp-input';
import { PreloadAllModules, RouterModule } from '@angular/router';
import '../styles.scss';
// custom imports
import { ComponentService } from './shared/services/component.service';
import { AuthService } from './shared/services/auth.service';
import { ShopService } from './shared/services/shop.service';
import { AuthGuard } from './shared/services/auth.guard';
import { OrderGuard } from './shared/services/order.guard';
import { PaymentService } from './shared/services/payment.service';
import { LanguageService } from './shared/services/language.service';
import { UtilityService } from './shared/services/utility.service';
import { EcurrenciesService } from './shared/services/ecurrencies.service';
import { WebClientService } from './shared/services/webclient-settings.service';
import { WebsiteThemeService } from './shared/services/website-theme.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { GoToPageService } from './shared/services/go-to-page.service';
import { SharedHttp } from './shared/services/http-client.service';
import { CopyToClipboardDirective } from './shared/directives/copy-to-clipboard.directive';
import { TranslateObjectPipe } from './shared/pipes/translate-object.pipe';
import { CurrencyFormatPipe } from './shared/pipes/currency-format.pipe';
import { AboutComponent } from './shared/pages/about';
import { PendingScreenComponent } from './shared/pages/pending-screen';
import { UploadDocumentScreenComponent } from './shared/pages/upload-document-screen';
import { KycQuestionnaireComponent } from './shared/pages/kyc-questionnaire';
import { ActivateComponent } from './shared/pages/activate';
import { ActivateDeviceComponent } from './shared/pages/activate-device';
import { AllowModalComponent } from './shared/pages/allow-modal';
import { CardPaymentDetailsComponent } from './shared/pages/card-payment-details';
import { ChoosePayingComponent } from './shared/pages/choose-paying';
import { SpinnerComponent } from './shared/pages/spinner/spinner.component';
import { SpinnerService } from './shared/pages/spinner/spinner.service';
import { ConfirmComponent } from './shared/pages/confirm';
import { EcdConfirmComponent } from './shared/pages/confirm-component';
import { ConsultationComponent } from './shared/pages/consultation';
import { CountdownTimerComponent } from './shared/pages/countdown-timer';
import { CryptoAtmsComponent } from './shared/pages/crypto-atms';
import { EcdServicesComponent } from './shared/pages/ecd-services';
import { FaqComponent } from './shared/pages/faq';
import { HashAndGoComponent } from './shared/pages/hash-and-go';
import { HeaderComponent } from './shared/pages/header';
import { HighlightComponent } from './shared/pages/highlight';
import { LanguageSwitcherComponent } from './shared/pages/language-switcher';
import { LoginComponent } from './shared/pages/login';
import { ModalComponent } from './shared/pages/modal';
import { NewsComponent } from './shared/pages/news';
import { OrderFormComponent } from './shared/pages/order-form-component';
import { Pay2MeComponent } from './shared/pages/pay2me';
import { PaymentInstructionsComponent } from './shared/pages/payment-instructions';
import { PersonalModalComponent } from './shared/pages/personal-verification-modal/personal-verification-modal.component';
import { PopUpInfoComponent } from './shared/pages/pop-up-info';
import { PreconfirmComponent } from './shared/pages/preconfirm';
import { PricesComponent } from './shared/pages/prices';
import { ProvisionsComponent } from './shared/pages/provisions';
import { ReferralsComponent } from './shared/pages/referrals';
import { RegisterComponent } from './shared/pages/register';
import { ResetComponent } from './shared/pages/reset';
import { ResetPasswordComponent } from './shared/pages/reset-password';
import { BuyComponent } from './shared/pages/shop/buy/buy';
import { ConfirmBuyComponent } from './shared/pages/shop/buy/confirm';
import { EndBuyComponent } from './shared/pages/shop/buy/end';
import { HomeBuyComponent } from './shared/pages/shop/buy';
import { SellComponent } from './shared/pages/shop/sell/sell';
import { ConfirmSellComponent } from './shared/pages/shop/sell/confirm';
import { EndSellComponent } from './shared/pages/shop/sell/end';
import { HomeSellComponent } from './shared/pages/shop/sell';
import { SideMenuComponent } from './shared/pages/side-menu';
import { SideMenuFeeComponent } from './shared/pages/side-menu-fee';
import { SingleConsultationComponent } from './shared/pages/single-consultation';
import { SlipModalComponent } from './shared/pages/slip-modal';
import { SmsConfirmationComponent } from './shared/pages/sms-confirmation-component';
import { StandingOrderComponent } from './shared/pages/standing-order';
import { SummaryDetailsComponent } from './shared/pages/summary-details';
import { TransactionsComponent } from './shared/pages/transactions';
import { UnderComponent } from './shared/pages/under';
import { VerifyAtmModalComponent } from './shared/pages/verification-modals/verify-atm/verify-atm-modal.component';
import { VerifyCallModalComponent } from './shared/pages/verification-modals/verify-call/verify-call-modal.component';
import { VerifyLetterModalComponent } from './shared/pages/verification-modals/verify-letter/verify-letter-modal.component';
import { VerifyOfficeModalComponent } from './shared/pages/verification-modals/verify-office/verify-office-modal.component';
import { VerifyCourierModalComponent } from './shared/pages/verification-modals/verify-courier/verify-courier-modal.component';
import { VerifyThirdPartiesModalComponent } from './shared/pages/verification-modals/verify-thirdparties/verify-thirdparties-modal.component';
import { VerifyComponent } from './shared/pages/verify';
import { VoucherComponent } from './shared/pages/voucher';
import { VoucherBannerComponent } from './shared/pages/voucher-banner';
import { WebShopComponent } from './shared/pages/webshop';
import { CartComponent } from './shared/pages/webshop/components/cart';
import { CheckoutComponent } from './shared/pages/webshop/components/checkout';
import { ConfirmShopComponent } from './shared/pages/webshop/components/confirm';
import { EndShopComponent } from './shared/pages/webshop/components/end';
import { ShopModalComponent } from './shared/pages/webshop/components/modal';
import { HomeComponent } from './shared/pages/home';
import { ContactComponent } from './shared/pages/contact';
import { AccountComponent } from './shared/pages/account';
import { AccountsComponent } from './shared/pages/account/accounts';
import { ChangeMobileComponent } from './shared/pages/account/change-mobile';
import { ChangePasswordComponent } from './shared/pages/account/change-password';
import { DataComponent } from './shared/pages/account/data';
import { NotificationsComponent } from './shared/pages/account/notifications';
import { EducationComponent } from './shared/pages/education';
import { ConfirmGuestComponent } from './shared/pages/create-guest-transactions/confirm';
import { CreateTransactionComponent } from './shared/pages/create-guest-transactions/create-transaction';
import { EndGuestComponent } from './shared/pages/create-guest-transactions/end';
import { SuccessComponent } from './shared/pages/voucher/success';
import { GoToPageComponent } from './shared/pages/go-to-page';
import { QrScannerModalComponent } from './shared/pages/qr-scanner-modal';
import { QrScannerModalService } from './shared/pages/qr-scanner-modal/qr-scanner-modal.service';
import { PricesService } from './shared/services/prices.service';
import { CurrenciesDropdownComponent } from './shared/pages/currencies-dropdown';
import { LockedUserActivationComponent } from './shared/pages/locked-user-activation';
import { PersonalVerificationService } from './shared/services/personal-verification.service';
import { QuestionnaireComponent } from './shared/pages/questionnaire';
import { DocumentationComponent } from './shared/pages/documentation';
import { NewBankAccountComponent } from './shared/pages/account/new-bank-account';
import { NewEAccountComponent } from './shared/pages/account/new-eaccount';
import { CompanyDataComponent } from './shared/pages/account/company-data';
import { CountriesDropdownComponent } from './shared/pages/countries-dropdown';
import { ModernDropdownComponent } from './shared/pages/modern-dropdown';
import { WalletComponent } from './shared/pages/wallet/wallet.component';
import { WalletTransactionsComponent } from './shared/pages/wallet/wallet-transactions/wallet-transactions.component';
import { WalletSettingsComponent } from './shared/pages/wallet/wallet-settings/wallet-settings.component';
import { NoCommaPipe, WalletCryptocurrenciesComponent } from './shared/pages/wallet/wallet-cryptocurrencies/wallet-cryptocurrencies.component';
import { AuthenticationComponent } from './shared/pages/account/authentication/authentication.component';
import { CreateWalletModalComponent } from './shared/pages/wallet/create-wallet-modal/create-wallet-modal.component';
import { DepositWalletModalComponent } from './shared/pages/wallet/deposit-wallet-modal/deposit-wallet-modal.component';
import { DepositWalletAudModalComponent } from './shared/pages/wallet/deposit-wallet-modal/deposit-wallet-aud-modal.component';
import { WithdrawWalletModalComponent } from './shared/pages/wallet/withdraw-wallet-modal/withdraw-wallet-modal.component';
import { WithdrawWalletAudModalComponent } from './shared/pages/wallet/withdraw-wallet-modal/withdraw-wallet-aud-modal.component';
import { AuthenticationWalletModalComponent } from './shared/pages/wallet/authentication-wallet-modal/authentication-wallet-modal.component';
import { WelcomeWalletModalComponent } from './shared/pages/wallet/welcome-wallet-modal/welcome-wallet-modal.component';
import { WalletInvitationModalComponent } from './shared/pages/wallet/wallet-invitation-modal/wallet-invitation-modal.component';
import { UpdateUserDataWalletModalComponent } from './shared/pages/wallet/update-user-data-wallet-modal/update-user-data-wallet-modal.component';
import { UpdateUserDataModalComponent } from './shared/pages/update-user-data-modal/update-user-data-modal.component';
import { ContractComponent } from './shared/pages/account/contract/contract.component';
import { DisableMfaModalComponent } from './shared/pages/account/authentication/disable-mfa-modal/disable-mfa-modal.component';
import { CoinFiatWalletModalComponent } from './shared/pages/wallet/coin-fiat-wallet-modal/coin-fiat-wallet-modal.component';
import { CoinDetailsComponent } from './shared/pages/wallet/coin-details/coin-details.component';
import { IgxCategoryChartModule } from 'igniteui-angular-charts';
import { CampaignsComponent } from './shared/pages/campaigns/campaigns.component';
import { SubscribeUnregisteredComponent } from './shared/pages/subscribe-unregistered/subscribe-unregistered.component';
import { WalletServiceNotAvailableComponent } from "./shared/pages/wallet/wallet-service-not-available-modal/wallet-service-not-available-modal";
import { BuyWalletModalComponent } from './shared/pages/wallet/buy-wallet-modal/buy-wallet-modal.component';
import { SellWalletModalComponent } from './shared/pages/wallet/sell-wallet-modal/sell-wallet-modal.component';
import { PaymentInstructionsWalletModalComponent } from './shared/pages/wallet/payment-instructions-wallet-modal/payment-instructions-wallet-modal.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OrderService } from "./shared/services/order.service";
import { ResetWalletPasswordModalComponent } from './shared/pages/wallet/reset-wallet-password-modal/reset-wallet-password-modal.component';
import { SessionExpirationComponent } from './shared/pages/session-expiration/session-expiration.component';
import { ResetMfaConfirmationModalComponent } from './shared/pages/account/authentication/reset-mfa-confirmation-modal/reset-mfa-confirmation-modal.component';
import { ResetMfaMessageModalComponent } from './shared/pages/wallet/reset-mfa-message-modal/reset-mfa-message-modal.component';
import { DocumentExpirationModalComponent } from './shared/pages/documentation/document-expiration-modal/document-expiration-modal.component';
import { WalletDocumentExpirationModalComponent } from './shared/pages/wallet/wallet-document-expiration-modal/wallet-document-expiration-modal.component';
import { PromotionComponent } from './shared/pages/promotion/promotion.component';
import { LimitExceededModalWalletComponent } from './shared/pages/wallet/limit-exceeded-modal-wallet/limit-exceeded-modal-wallet.component';
import { LimitExceededModalComponent } from './shared/pages/limit-exceeded-modal/limit-exceeded-modal.component';
import { ChangeWalletPasswordComponent } from "./shared/pages/account/change-wallet-password";
import { DeleteRequestModalComponent } from "./shared/pages/account/delete-request/delete-request-modal.component";
import { BuyBasketWalletModalComponent } from './shared/pages/wallet/buy-basket-wallet-modal/buy-basket-wallet-modal.component';
import { AddAddressToWhitelistModalComponent } from './shared/pages/wallet/add-address-to-whitelist-modal/add-address-to-whitelist-modal.component';
import { WhitelistAddressConfirmationMailSentComponent } from './shared/pages/whitelist-address-confirmation-mail-sent/whitelist-address-confirmation-mail-sent.component';
import { WhitelistAddressApprovedModalComponent } from './shared/pages/wallet/whitelist-address-approved-modal/whitelist-address-approved-modal.component';
import { InactiveGuard } from "./shared/services/inactive.guard";
import { BuySellInfoModalComponent } from './shared/pages/buy-sell-info-modal/buy-sell-info-modal.component';
import { CheckProtocolModalComponent } from './shared/pages/check-protocol-modal/check-protocol-modal.component';
import { CheckProtocolModalWalletComponent } from './shared/pages/wallet/check-protocol-modal-wallet/check-protocol-modal-wallet.component';
import { SuccessfulRegistrationComponent } from './shared/pages/register/successful-registration/successful-registration.component';
import { GoToBlinkingComponent } from './shared/pages/verify/blinking/go-to-blinking/go-to-blinking.component';
import { GeneralCompanyInformationComponent } from './shared/pages/register/legal-entity-registration/general-company-information/general-company-information.component';
import { CompanyLocationInformationComponent } from './shared/pages/register/legal-entity-registration/company-location-information/company-location-information.component';
import { MobileNumberValidationComponent } from './shared/pages/verify/mobile-number-validation/mobile-number-validation.component';
import { GeneralPersonalDataComponent } from './shared/pages/verify/general-personal-data/general-personal-data.component';
import { BlinkingNotAvailableComponent } from './shared/pages/verify/blinking/blinking-not-available/blinking-not-available.component';
import { ContinueBlinkingProcessComponent } from './shared/pages/verify/blinking/continue-blinking-process/continue-blinking-process.component';
import { FinishedBlinkingPhaseTwoComponent } from './shared/pages/verify/blinking/finished-blinking-phase-two/finished-blinking-phase-two.component';
import { AdditionalUserInformationComponent } from './shared/pages/verify/additional-user-information/additional-user-information.component';
import { UserDocumentationComponent } from './shared/pages/verify/user-documentation/user-documentation.component';
import { PersonalVerificationComponent } from './shared/pages/verify/personal-verification/personal-verification.component';
import { CompanyDocumentationComponent } from './shared/pages/verify/company-documentation/company-documentation.component';
import { EuProofOfAddressDocumentationComponent } from './shared/pages/verify/eu-proof-of-address-documentation/eu-proof-of-address-documentation.component';
let AppModule = class AppModule {
    constructor(appRef) {
        this.appRef = appRef;
    }
};
AppModule.ctorParameters = () => [
    { type: ApplicationRef }
];
AppModule = __decorate([
    NgModule({
        declarations: [
            AppComponent,
            CopyToClipboardDirective,
            TranslateObjectPipe,
            CurrencyFormatPipe,
            AboutComponent,
            PendingScreenComponent,
            UploadDocumentScreenComponent,
            KycQuestionnaireComponent,
            AccountComponent,
            AccountsComponent,
            DataComponent,
            ChangeMobileComponent,
            ChangePasswordComponent,
            ActivateComponent,
            ActivateDeviceComponent,
            AllowModalComponent,
            CardPaymentDetailsComponent,
            ChoosePayingComponent,
            SpinnerComponent,
            ConfirmComponent,
            EcdConfirmComponent,
            NotificationsComponent,
            ConsultationComponent,
            ContactComponent,
            CountdownTimerComponent,
            ConfirmGuestComponent,
            CreateTransactionComponent,
            EndGuestComponent,
            CryptoAtmsComponent,
            EcdServicesComponent,
            EducationComponent,
            FaqComponent,
            GoToPageComponent,
            HashAndGoComponent,
            HeaderComponent,
            HighlightComponent,
            HomeComponent,
            LanguageSwitcherComponent,
            LoginComponent,
            ModalComponent,
            NewsComponent,
            OrderFormComponent,
            Pay2MeComponent,
            PaymentInstructionsComponent,
            PersonalModalComponent,
            PopUpInfoComponent,
            PreconfirmComponent,
            PricesComponent,
            ProvisionsComponent,
            ReferralsComponent,
            RegisterComponent,
            ResetComponent,
            ResetPasswordComponent,
            BuyComponent,
            ConfirmBuyComponent,
            EndBuyComponent,
            HomeBuyComponent,
            SellComponent,
            ConfirmSellComponent,
            EndSellComponent,
            HomeSellComponent,
            SideMenuComponent,
            SideMenuFeeComponent,
            SingleConsultationComponent,
            SlipModalComponent,
            SmsConfirmationComponent,
            StandingOrderComponent,
            SummaryDetailsComponent,
            TransactionsComponent,
            UnderComponent,
            VerifyAtmModalComponent,
            VerifyCallModalComponent,
            VerifyCourierModalComponent,
            VerifyThirdPartiesModalComponent,
            VerifyLetterModalComponent,
            VerifyOfficeModalComponent,
            VerifyComponent,
            VoucherComponent,
            SuccessComponent,
            VoucherBannerComponent,
            WebShopComponent,
            CartComponent,
            CheckoutComponent,
            ConfirmShopComponent,
            EndShopComponent,
            ShopModalComponent,
            QrScannerModalComponent,
            CurrenciesDropdownComponent,
            LockedUserActivationComponent,
            QuestionnaireComponent,
            DocumentationComponent,
            NewBankAccountComponent,
            NewEAccountComponent,
            CompanyDataComponent,
            CountriesDropdownComponent,
            ModernDropdownComponent,
            WalletComponent,
            WalletTransactionsComponent,
            WalletSettingsComponent,
            WalletCryptocurrenciesComponent,
            AuthenticationComponent,
            CreateWalletModalComponent,
            DepositWalletModalComponent,
            DepositWalletAudModalComponent,
            WithdrawWalletModalComponent,
            WithdrawWalletAudModalComponent,
            AuthenticationWalletModalComponent,
            WelcomeWalletModalComponent,
            WalletInvitationModalComponent,
            UpdateUserDataWalletModalComponent,
            UpdateUserDataModalComponent,
            DisableMfaModalComponent,
            CoinFiatWalletModalComponent,
            ContractComponent,
            CoinDetailsComponent,
            WalletServiceNotAvailableComponent,
            NoCommaPipe,
            CampaignsComponent,
            SubscribeUnregisteredComponent,
            BuyWalletModalComponent,
            SellWalletModalComponent,
            PaymentInstructionsWalletModalComponent,
            ResetWalletPasswordModalComponent,
            SessionExpirationComponent,
            ResetMfaConfirmationModalComponent,
            ResetMfaMessageModalComponent,
            DocumentExpirationModalComponent,
            WalletDocumentExpirationModalComponent,
            PromotionComponent,
            LimitExceededModalWalletComponent,
            LimitExceededModalComponent,
            ChangeWalletPasswordComponent,
            DeleteRequestModalComponent,
            BuyBasketWalletModalComponent,
            AddAddressToWhitelistModalComponent,
            WhitelistAddressConfirmationMailSentComponent,
            WhitelistAddressApprovedModalComponent,
            BuySellInfoModalComponent,
            CheckProtocolModalComponent,
            CheckProtocolModalWalletComponent,
            SuccessfulRegistrationComponent,
            GoToBlinkingComponent,
            GeneralCompanyInformationComponent,
            CompanyLocationInformationComponent,
            MobileNumberValidationComponent,
            GeneralPersonalDataComponent,
            BlinkingNotAvailableComponent,
            ContinueBlinkingProcessComponent,
            FinishedBlinkingPhaseTwoComponent,
            AdditionalUserInformationComponent,
            UserDocumentationComponent,
            PersonalVerificationComponent,
            CompanyDocumentationComponent,
            EuProofOfAddressDocumentationComponent,
        ],
        exports: [
            LoginComponent,
            ModalComponent,
            RegisterComponent,
            SlipModalComponent,
            ResetPasswordComponent,
            ConfirmComponent,
            AllowModalComponent,
            ShopModalComponent,
            PersonalModalComponent,
            VerifyLetterModalComponent,
            VerifyAtmModalComponent,
            VerifyCallModalComponent,
            VerifyOfficeModalComponent,
            VerifyCourierModalComponent,
            VerifyThirdPartiesModalComponent
        ],
        entryComponents: [
            LoginComponent,
            RegisterComponent,
            ModalComponent,
            SlipModalComponent,
            ResetPasswordComponent,
            ConfirmComponent,
            AllowModalComponent,
            ShopModalComponent,
            PersonalModalComponent,
            VerifyLetterModalComponent,
            VerifyAtmModalComponent,
            VerifyCallModalComponent,
            VerifyCourierModalComponent,
            VerifyOfficeModalComponent,
            QrScannerModalComponent,
            VerifyThirdPartiesModalComponent,
            CreateWalletModalComponent,
            DepositWalletModalComponent,
            DepositWalletAudModalComponent,
            WithdrawWalletModalComponent,
            WithdrawWalletAudModalComponent,
            AuthenticationWalletModalComponent,
            WelcomeWalletModalComponent,
            WalletInvitationModalComponent,
            UpdateUserDataWalletModalComponent,
            UpdateUserDataModalComponent,
            DisableMfaModalComponent,
            CoinFiatWalletModalComponent,
            WalletServiceNotAvailableComponent,
            BuyWalletModalComponent,
            SellWalletModalComponent,
            PaymentInstructionsWalletModalComponent,
            ResetWalletPasswordModalComponent,
            SessionExpirationComponent,
            ResetMfaConfirmationModalComponent,
            ResetMfaMessageModalComponent,
            DocumentExpirationModalComponent,
            WalletDocumentExpirationModalComponent,
            LimitExceededModalWalletComponent,
            LimitExceededModalComponent,
            DeleteRequestModalComponent,
            BuyBasketWalletModalComponent,
            AddAddressToWhitelistModalComponent,
            WhitelistAddressConfirmationMailSentComponent,
            WhitelistAddressApprovedModalComponent,
            BuySellInfoModalComponent,
            CheckProtocolModalComponent,
            CheckProtocolModalWalletComponent,
            SuccessfulRegistrationComponent,
            GeneralCompanyInformationComponent,
            CompanyLocationInformationComponent
        ],
        imports: [
            BrowserModule,
            AppRoutingModule,
            AgmCoreModule.forRoot({
                apiKey: 'AIzaSyArXcqD-DzEI2ChaKMIwqNi6Q2y2X5D274'
            }),
            AgmJsMarkerClustererModule,
            Ng2FileSizeModule,
            FileUploadModule,
            MetaModule.forRoot(),
            CountdownModule,
            PopoverModule.forRoot(),
            RouterModule.forRoot(ROUTES, {
                useHash: false,
                preloadingStrategy: PreloadAllModules
            }),
            AccordionModule.forRoot(),
            CarouselModule.forRoot(),
            BrowserAnimationsModule,
            AutosizeModule,
            FormsModule,
            ReactiveFormsModule,
            HttpClientModule,
            SlickCarouselModule,
            ZXingScannerModule,
            DeviceDetectorModule.forRoot(),
            AutocompleteLibModule,
            IgxCategoryChartModule,
            MatProgressBarModule,
            MatTooltipModule,
            NgIdleKeepaliveModule.forRoot(),
            NgOtpInputModule
        ],
        providers: [
            ComponentService,
            AuthService,
            HttpClient,
            ShopService,
            AuthGuard,
            OrderGuard,
            PaymentService,
            LanguageService,
            UtilityService,
            EcurrenciesService,
            WebClientService,
            WebsiteThemeService,
            GoogleAnalyticsService,
            GoToPageService,
            SharedHttp,
            SpinnerService,
            QrScannerModalService,
            PricesService,
            PersonalVerificationService,
            TranslateObjectPipe,
            OrderService,
            InactiveGuard,
        ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
        bootstrap: [AppComponent]
    })
], AppModule);
export { AppModule };
