import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
let AuthGuard = class AuthGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate() {
        this.authService.fetchUser().then(data => {
            if (data) {
                return true;
            }
            else {
                this.router.navigateByUrl('');
                return false;
            }
        });
    }
};
AuthGuard.ctorParameters = () => [
    { type: AuthService },
    { type: Router }
];
AuthGuard = __decorate([
    Injectable()
], AuthGuard);
export { AuthGuard };
