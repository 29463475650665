import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedHttp } from './http-client.service';
import { BehaviorSubject } from 'rxjs';
let WebClientService = class WebClientService {
    constructor(router, httpClient) {
        this.router = router;
        this.httpClient = httpClient;
        this.websettings = {};
        this.routesObject = {};
        this.settings = {};
        this.langFile = {};
        this.errorNoLang = '';
        this.dataSource = new BehaviorSubject(null);
        this.data = this.dataSource.asObservable();
        this.errorSubject = new BehaviorSubject(false);
        this.value = this.errorSubject.asObservable();
    }
    getSettings(language) {
        return new Promise((resolve) => {
            this.httpClient.get('webclient/settings' + ((language !== null && language !== undefined) ? '?language=' + language : ''))
                .subscribe((data) => {
                this.settings = data;
                if (this.settings.settings.length === 0 || this.settings.language_file.length === 0) {
                    this.errorSubject.next(true);
                    resolve(false);
                }
                else {
                    this.dataSource.next(true);
                    this.errorSubject.next(false);
                    resolve(true);
                }
                return data;
            }, (error) => {
                if (error.status === 404) {
                    if (JSON.stringify(error)[0] !== '<') {
                        this.errorNoLang = error.error['message'];
                    }
                    if (this.errorNoLang === 'LANGUAGE_DOES_NOT_EXIST') {
                        this.getSettings(null);
                    }
                    else {
                        this.errorSubject.next(true);
                        resolve(false);
                    }
                }
                else {
                    this.errorSubject.next(true);
                    resolve(false);
                }
            });
        });
    }
    getLanguageFile() {
        const languageFile = this.settings.language_file;
        return languageFile;
    }
    getAvailableLanguages() {
        const languages = this.settings.languages;
        return languages;
    }
    getCurrentLanguage() {
        const language = this.settings.currentLanguage;
        return language;
    }
    getDefaultLanguage() {
        const language = this.settings.defaultLanguage;
        return language;
    }
    getWebSettings() {
        const webSettings = this.settings.settings;
        return webSettings;
    }
    getRoutes() {
        const routes = this.settings.routes;
        return routes;
    }
    applySettings(key) {
        this.websettings = this.getWebSettings();
        if (this.websettings !== null && typeof this.websettings !== 'undefined') {
            if (this.websettings[key] === '1' ||
                this.websettings[key] === 'true' ||
                this.websettings[key] === 'on' ||
                this.websettings[key] === 'ON' ||
                this.websettings[key] === 'redirect') {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    getWebSetting(key) {
        this.websettings = this.getWebSettings();
        if (this.websettings !== null && typeof this.websettings !== 'undefined') {
            if (key === 'DEFAULT_ROUTE') {
                return this.websettings[key].trim();
            }
            else {
                return this.websettings[key];
            }
        }
        return null;
    }
    redirectToHome(route) {
        if (route.indexOf('/news') !== -1) {
            route = '/news';
        }
        this.websettings = this.getWebSettings();
        this.routesObject = this.getRoutes();
        if (this.websettings) {
            const key = this.routesObject[route];
            if (route in this.routesObject && this.applySettings(key)) {
                this.router.navigateByUrl('');
            }
            if (this.routesObject && (route in this.routesObject)) {
                const navigateTo = this.routesObject[route];
                this.router.navigateByUrl(navigateTo);
            }
        }
    }
    checkForKey(key) {
        this.langFile = this.getLanguageFile();
        if (this.langFile !== null && typeof this.langFile !== 'undefined') {
            if (key in this.langFile) {
                if (this.langFile[key] === '' || this.langFile[key].trim().length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    emptySettings() {
        this.websettings = this.getWebSettings();
        this.langFile = this.getLanguageFile();
        if ((this.websettings !== null && typeof this.websettings !== 'undefined') ||
            (this.langFile !== null && typeof this.langFile !== 'undefined')) {
            if (this.websettings.length === 0 || this.langFile.length === 0) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    getKeyValue(key) {
        this.langFile = this.getLanguageFile();
        if (this.langFile !== null && typeof this.langFile !== 'undefined') {
            if (key in this.langFile) {
                return this.langFile[key].trim();
            }
        }
        return '';
    }
    getCustomValue(key) {
        this.websettings = this.getWebSettings();
        const substrigValue = '.VALUE';
        if (this.websettings !== null && typeof this.websettings !== 'undefined') {
            return (key.indexOf(substrigValue) !== -1) ? this.websettings[key] : '';
        }
        else {
            return '';
        }
    }
};
WebClientService.ctorParameters = () => [
    { type: Router },
    { type: SharedHttp }
];
WebClientService = __decorate([
    Injectable()
], WebClientService);
export { WebClientService };
