import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedHttp } from './http-client.service';
import { BehaviorSubject } from 'rxjs/';
import { ComponentService } from "./component.service";
let AuthService = class AuthService {
    constructor(router, httpClient, compService) {
        this.router = router;
        this.httpClient = httpClient;
        this.compService = compService;
        this.logOUT = {};
        this.loggedIn = false;
        this.orderAllow = false;
        this.loggedIn$ = new BehaviorSubject(this.loggedIn);
        this.orderAllow$ = new BehaviorSubject(this.orderAllow);
        this.user = {};
        this.company = {};
    }
    fetchUser() {
        return new Promise((resolve) => {
            this.httpClient.get('user/data').subscribe((user) => {
                const u = user;
                u['mean_bank_slip_sell'] = 'bank_slip';
                localStorage.setItem('user', JSON.stringify(u));
                if (user['orders_allowed'] === true) {
                    this.setOrderAllow(true);
                }
                this.setLoggedIn(true);
                resolve(true);
            }, (error) => {
                resolve(false);
                if (error.status === 401) {
                    this.logOut();
                }
                clearInterval(this.intervalId);
                this.setLoggedIn(false);
            });
        });
    }
    checkLocalStorage() {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user !== null) {
            return true;
        }
        else {
            return false;
        }
    }
    ordersAllowed() {
        return new Promise((resolve) => {
            this.httpClient.get('user/data').subscribe((user) => {
                this.company = this.user.company;
                if (user['orders_allowed'] === 1 ||
                    ((user['validated'] === 1 && this.company == null)
                        || (this.company !== null && this.company.verified == 1 && this.user.personally_validated == 1))) {
                    this.setOrderAllow(true);
                    resolve(true);
                }
                else {
                    this.setOrderAllow(false);
                    this.router.navigateByUrl('verify');
                    resolve(false);
                }
            }, (error) => {
                this.setOrderAllow(false);
                resolve(false);
            });
        });
    }
    setToken(token, tokenDuration) {
        localStorage.setItem('auth_token', token);
        localStorage.setItem('token_duration', tokenDuration);
        const expiryDate = new Date();
        expiryDate.setSeconds(expiryDate.getSeconds() + tokenDuration * 60);
        localStorage.setItem('expiry_date', expiryDate.toString());
        this.extendUserSession(localStorage.getItem('expiry_date'));
    }
    extendUserSession(expiryDate) {
        clearInterval(this.sessionIntervalId);
        this.sessionIntervalId = setInterval(() => {
            const difference = (new Date(expiryDate).getTime() - new Date().getTime()) / 1000;
            if (this.loggedIn) {
                if (difference <= 1) {
                    this.httpClient.post('user/extendusersession', null).subscribe((data) => {
                        const newExpiryDate = new Date();
                        newExpiryDate.setSeconds(newExpiryDate.getSeconds() + Number(localStorage.getItem('token_duration')) * 60);
                        localStorage.setItem('expiry_date', newExpiryDate.toString());
                        this.extendUserSession(newExpiryDate);
                    });
                }
            }
            else {
                clearInterval(this.sessionIntervalId);
            }
        }, 250);
    }
    setLoggedIn(value) {
        this.loggedIn$.next(value);
        this.loggedIn = value;
    }
    setOrderAllow(value) {
        this.orderAllow$.next(value);
        this.orderAllow = value;
    }
    getStatus() {
        return this.loggedIn$.asObservable();
    }
    getOrderStatus() {
        return this.orderAllow;
    }
    logOut(goToApi) {
        if (typeof goToApi === 'undefined' || goToApi === null) {
            goToApi = false;
        }
        this.logOUT.auth = localStorage.getItem('auth_token');
        if (goToApi) {
            this.httpClient.post('logout', this.logOUT).subscribe((data) => {
                this.setLoggedIn(false);
                this.router.navigateByUrl('');
            });
        }
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');
        localStorage.removeItem('expiry_date');
        localStorage.removeItem('token_duration');
    }
};
AuthService.ctorParameters = () => [
    { type: Router },
    { type: SharedHttp },
    { type: ComponentService }
];
AuthService = __decorate([
    Injectable()
], AuthService);
export { AuthService };
