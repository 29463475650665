import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { SharedHttp } from './http-client.service';
import { BehaviorSubject } from 'rxjs';
let WebsiteThemeService = class WebsiteThemeService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.themeDetails = {};
        this.errorSubject = new BehaviorSubject(false);
        this.value = this.errorSubject.asObservable();
    }
    // kada se dobije error idi na under-maintenance
    getTheme() {
        return new Promise((resolve) => {
            this.httpClient.get('themes/gettheme').subscribe((data) => {
                this.themeDetails = data;
                this.errorSubject.next(false);
                resolve(true);
                return data;
            }, (error) => {
                this.errorSubject.next(true);
                resolve(false);
            });
        });
    }
    getStyleAttribute(item) {
        const value = this.themeDetails.content[item];
        return value;
    }
};
WebsiteThemeService.ctorParameters = () => [
    { type: SharedHttp }
];
WebsiteThemeService = __decorate([
    Injectable()
], WebsiteThemeService);
export { WebsiteThemeService };
