// use https://api-dev.ecd.rs/api/ and https://admin-dev.ecd.rs/api/users/uploadimage for development env once everything is up and running
const configuration = {
  local: {
    apiUrl: 'http://api-local.ecd.rs:8081/api/',
    uploadUrl: 'http://admin-local.ecd.rs:8082/api/users/uploadimage'
  },
  development: {
    apiUrl: 'https://api-dev.ecd.rs/api/',
    uploadUrl: 'https://admin-dev.ecd.rs/api/users/uploadimage'
  },
  feature: {
    apiUrl: 'https://api-feature.ecd.rs/api/',
    uploadUrl: 'https://admin-feature.ecd.rs/api/users/uploadimage'
  },
  qa: {
    apiUrl: 'https://api-qa.ecd.rs/api/',
    uploadUrl: 'https://admin-qa.ecd.rs/api/users/uploadimage'
  },
  stage: {
    apiUrl: 'https://api-stage.ecd.rs/api/',
    uploadUrl: 'https://admin-stage.ecd.rs/api/users/uploadimage'
  },
  production: {
    apiUrl: 'https://api.ecd.rs/api/',
    uploadUrl: 'https://admin.ecd.rs/api/users/uploadimage'
  },
  frnc_ecd_0_stage: {
    apiUrl: 'https://api-stage.cryptomonte.me/api/',
    uploadUrl: 'https://admin-stage.cryptomonte.me/api/users/uploadimage'
  },
  ecd_mk: {
    apiUrl: 'https://api.ecd.mk/api/',
    uploadUrl: 'https://admin.ecd.mk/api/users/uploadimage'
  },
  ecd_mk_stage: {
    apiUrl: 'https://api-stage.ecd.mk/api/',
    uploadUrl: 'https://admin-stage.ecd.mk/api/users/uploadimage'
  },
  ecd_cg: {
    apiUrl: 'https://api.cryptomonte.me/api/',
    uploadUrl: 'https://admin.cryptomonte.me/api/users/uploadimage'
  },
  ecd_eu: {
    apiUrl: 'https://api.ecd.exchange/api/',
    uploadUrl: 'https://admin.ecd.exchange/api/users/uploadimage'
  },
  ecd_eu_stage: {
    apiUrl: 'https://api-stage.ecd.exchange/api/',
    uploadUrl: 'https://admin-stage.ecd.exchange/api/users/uploadimage'
  },
  ecd_au_stage: {
    apiUrl: 'https://api-stage.ecdaustralia.com/api/',
    uploadUrl: 'https://admin-stage.ecdaustralia.com/api/users/uploadimage'
  },
  ecd_au: {
    apiUrl: 'https://api.ecdaustralia.com/api/',
    uploadUrl: 'https://admin.ecdaustralia.com/api/users/uploadimage'
  }
};

const seo = {
  dev: {
    home: {
      title: 'ECD - Prodaja i kupovina kriptovaluta od 2012. godine.',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    reset: {
      title: 'ECD.rs - promena šifre',
      description: 'ecd.rs home description',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    account: {
      title: 'ECD.rs - promena naloga',
      description: 'ecd.rs home description',
      keywords:'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    faq: {
      title: 'ECD.rs - često postavljena pitanja',
      description: 'Saznajte više, kako da pravilno koristite usluge koje su Vam omogućene, u sekciji "Često postavljena pitanja"',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, FAQ'
    },
    provisions: {
      title: 'ECD.rs - Troškovi transakcija',
      description: 'Informišite se više o troškovima transakcija za kupovinu i prodaju kripto valuta na ECD.rs',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    contact: {
      title: 'ECD.rs - Kontaktirajte nas',
      description: 'Ukoliko želite da nas kontaktirate to možete učiniti putem sajta koristeći kontakt formu. Radno vreme korisničke podrške je od 9 do 21h.',
      keywords: 'kontakt, pitajte, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, Contact'
    },
    transactions: {
      title: 'ECD.rs - transakcije',
      description: 'ecd.rs home description',
      keywords: 'transakcije, nalozi, kupovina, prodaja, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    crypto_atms: {
      title: 'ECD.rs - Crypto Automati',
      description: 'Saznajte više o mreži kripto automata u Srbiji. Takođe, možete saznati u kojim objektima možete usluge platiti kripto valutama. ',
      keywords: 'ATM, automati, mreža, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    news: {
      title: 'ECD.rs - Vesti',
      description: 'Saznajte više o aktuelnostima iz kripto sveta. Pratite naša obaveštenja od značaja za korisnike. ',
      keywords: 'vesti, obavestenja, kripto vesti, novosti, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    standing: {
      title: 'ECD.rs - Zahtev',
      description: '' ,
      keywords: 'podnet, zahtev, kupovina, prodaja, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    about: {
      title: 'ECD.rs - O nama',
      description: 'Kriptovalutama se bavimo od 2012. godine. Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'O nama, tim, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    questionnaire: {
      title: 'ECD.rs - Upitnik',
      description: '',
      keywords: ''
    },
    documentation: {
      title: 'ECD.rs - Dokumentacija',
      description: '',
      keywords: ''
    },
    campaign: {
      title: 'ECD.rs - Kripto Petak',
      description: '',
      keywords: ''
    },
    promotion: {
      title: 'ECD.rs - Promo Kod',
      description: '',
      keywords: ''
    },
    subscribe_unregistered: {
      title: 'ECD.rs - Prijava na Newsletter',
      description: '',
      keywords: ''
    },
    wallet: {
      title: 'ECD.rs - Wallet',
      description: '',
      keywords: ''
    },
    under: {
      title: 'ECD.rs - Ažuriranje sajta',
      description: 'Sajt se trenutno održava',
      keywords: 'under construction, ažuriranje, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    verify: {
      title: 'ECD.rs - Verifikacija naloga',
      description: 'ecd.rs verify description',
      keywords: 'nalog, verify, verifikacija'
    },
    consultation: {
      title: 'ECD.rs - Konsultacija',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    buy: {
      title: 'ECD.rs - Kupovina',
      description: "Kupovina kriptovalute",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    sell: {
      title: 'ECD.rs - Prodaja',
      description: "Prodaja kriptovalute",
      keywords: 'Prodaj, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    education: {
      title: 'ECD.rs - Edukacija',
      description: "Edukacija",
      keywords: 'predavanje, nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    voucher: {
      title: 'ECD.rs - Vaučer',
      description: "Edukacija",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka,Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    shop: {
      title: 'ECD.rs - Prodavnica',
      description: "Prodavnica",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    pay2me: {
      title: 'ECD.rs - Pay2Me',
      description: "Pay2Me",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    choose: {
      title: 'ECD.rs - Stranica za izbor plaćanja',
      description: 'Izbor plaćanja',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    ecd_services: {
      title: 'ECD.rs - Usluge koje nudimo',
      description: 'ECD usluge',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    hash_go: {
      title: 'ECD.rs - HASH&GO',
      description: 'Saznajte više o tome u kojim objektima možete usluge platiti bitkoinom. ',
      keywords: 'ATM, automati, mreza, hash&go, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    consult: {
      title: 'ECD.rs - Konsultacija',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom iz oblasti kriptovaluta i blockchaina",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    referrals: {
      title: 'ECD.rs - Referal linkovi',
      description: "ECD usluge",
      keywords: 'zaradi, nalog, referali, referrals, link, register, earn, preporuci, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    guest: {
      title: 'ECD - Prodaja i kupovina kriptovaluta od 2012. godine.',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    }
  },

  mk: {
    home: {
      title: 'ECD.mk - Prodažba i kupuvanje na kriptovaluti - Severna Makedonija.',
      description: 'Posle 7 uspešni godini i razvoj na srpskiot pazar, kompanijata ECD.rs se reši da gi ponudi svoite uslugi i na pazarot vo Makedonija. ECD.mk e makedonski ogranok na kompanijata koj nastanuva vo 2019 godina i e servis za posreduvanje pri kupuvanje i prodažba na kriptovalutite : Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    reset: {
      title: 'ECD.mk - Promena na lozinka',
      description: 'ECD.mk home description',
      keywords: 'Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    account: {
      title: 'ECD.mk - Podesuvanje na nalogot',
      description: 'ECD.mk home description',
      keywords: 'Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    faq: {
      title: 'ECD.mk - Često postaveni prašanja',
      description: 'Saznajte više, kako da pravilno koristite usluge koje su Vam omogućene, u sekciji "Često postavljena pitanja"',
      keywords: 'Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, FAQ'
    },
    provisions: {
      title: 'ECD.mk - Provizii',
      description: 'Informišite se više o provizijama kripto valuta sa našeg sajta ',
      keywords: 'Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    contact: {
      title: 'ECD.mk - Kontaktirajte ne',
      description: 'Ukoliko želite da nas kontaktirate to možete učiniti putem sajta koristeći kontakt formu. Radno vreme korisničke podrške je od 9 do 21h.',
      keywords: 'kontakt, pitajte, Bitcoin, MAkedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, Contact'
    },
    transactions: {
      title: 'ECD.mk - transakcii',
      description: 'ECD.mk home description',
      keywords: 'transakci, nalozi, kupovina, prodažba, Bitcoin, Makedonija, Macedonija, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    crypto_atms: {
      title: 'ECD.mk - Crypto Automati',
      description: 'Doznajte poveḱe za mrežata na kripto mašini vo Srbija. Može da otkriete i vo koi objekti možete da platite so kriptovaluti. ',
      keywords: 'ATM, automati, mreža, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    news: {
      title: 'ECD.mk - Vesti',
      description: 'Doznajte poveḱe za svetot na kripto. Sledete gi našite izvestuvanja od važnosta na klientot. ',
      keywords: 'vesti, izvestuvanja, kripto vesti, novosti, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    standing: {
      title: 'ECD.mk - Baranje',
      description: '' ,
      keywords: 'podnet, baranje, kupovina, prodaja, prodažba, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    about: {
      title: 'ECD.mk - Za nas',
      description: 'Posle 7 uspešni godini i razvoj na srpskiot pazar, kompanijata ECD.rs se reši da gi ponudi svoite uslugi i na pazarot vo Makedonija. ECD.mk e makedonski ogranok na kompanijata koj nastanuva vo 2019 godina i e servis za posreduvanje pri kupuvanje i prodažba na kriptovalutite : Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Za nas, tim, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    questionnaire: {
      title: 'ECD.mk - Upitnik',
      description: '',
      keywords: ''
    },
    documentation: {
      title: 'ECD.mk - Dokumentacija',
      description: '',
      keywords: ''
    },
    campaign: {
      title: 'ECD.mk - Kripto Petak',
      description: '',
      keywords: ''
    },
    promotion: {
      title: 'ECD.rs - Promo Kod',
      description: '',
      keywords: ''
    },
    subscribe_unregistered: {
      title: 'ECD.mk - Prijava na Newsletter',
      description: '',
      keywords: ''
    },
    wallet: {
      title: 'ECD.mk - Wallet',
      description: '',
      keywords: ''
    },
    under: {
      title: 'ECD.mk - Ažuriranje sajta',
      description: 'Sajt se trenutno održava',
      keywords: 'under construction, ažuriranje, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    verify: {
      title: 'ECD.mk - Verifikacija naloga',
      description: 'ECD.mk verify description',
      keywords: 'nalog, verify, verifikacija'
    },
    consultation: {
      title: 'ECD.mk - Konsultacii',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    buy: {
      title: 'ECD.mk - Kupovuvanje',
      description: "Kupovina kriptovalute",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    sell: {
      title: 'ECD.mk - Prodažba',
      description: "Prodaja kriptovalute",
      keywords: 'Prodaj, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    education: {
      title: 'ECD.mk - Edukacija',
      description: "Edukacija",
      keywords: 'predavanje, nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    voucher: {
      title: 'ECD.mk - Vaučer',
      description: "Edukacija",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka,Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    shop: {
      title: 'ECD.mk - Prodavnica',
      description: "Prodavnica",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    pay2me: {
      title: 'ECD.mk - HashandGo',
      description: "HashAndGo",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    choose: {
      title: 'ECD.mk - Stranica za izbor na plakanja',
      description: 'Izbor plakanja',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    ecd_services: {
      title: 'ECD.mk - Usluge koje nudimo',
      description: 'ECD usluge',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    hash_go: {
      title: 'ECD.mk - HASH&GO',
      description: 'Doznajte poveḱe vo koi objekti možete da platite so Bitcoin. ',
      keywords: 'ATM, automati, mreza, hash&go, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    consult: {
      title: 'ECD.mk - Konsultacija',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom iz oblasti kriptovaluta i blockchaina",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    referrals: {
      title: 'ECD.mk - Referal linkovi',
      description: "ECD usluge",
      keywords: 'zaradi, nalog, referali, referrals, link, register, earn, preporuci, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    guest: {
      title: 'ECD.mk - Prodažba i kupuvanje na kriptovaluti - Severna Makedonija.',
      description: 'Posle 7 uspešni godini i razvoj na srpskiot pazar, kompanijata ECD.rs se reši da gi ponudi svoite uslugi i na pazarot vo Makedonija. ECD.mk e makedonski ogranok na kompanijata koj nastanuva vo 2019 godina i e servis za posreduvanje pri kupuvanje i prodažba na kriptovalutite : Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Makedonija, Macedonia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    }
  },

  cg: {
    home: {
      title: 'CRYPTOMONTE.ME - Prodaja i kupovina kriptovaluta - Crna Gora.',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta u Crnoj Gori. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    reset: {
      title: 'CRYPTOMONTE.ME - promjena šifre',
      description: 'CRYPTOMONTE.ME home description',
      keywords: 'Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    account: {
      title: 'CRYPTOMONTE.ME - promjena naloga',
      description: 'CRYPTOMONTE.ME home description',
      keywords:'Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    faq: {
      title: 'CRYPTOMONTE.ME - često postavljena pitanja',
      description: 'Saznajte više, kako da pravilno koristite usluge koje su Vam omogućene, u sekciji "Često postavljena pitanja"',
      keywords: 'Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, FAQ'
    },
    provisions: {
      title: 'CRYPTOMONTE.ME - provizije',
      description: 'Informišite se više o provizijama kripto valuta sa našeg sajta ',
      keywords: 'Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    contact: {
      title: 'CRYPTOMONTE.ME - Kontaktirajte nas',
      description: 'Ukoliko želite da nas kontaktirate to možete učiniti putem sajta koristeći kontakt formu. Radno vreme korisničke podrške je od 9 do 21h.',
      keywords: 'kontakt, pitajte, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, Contact'
    },
    transactions: {
      title: 'CRYPTOMONTE.ME - transakcije',
      description: 'CRYPTOMONTE.ME home description',
      keywords: 'transakcije, nalozi, kupovina, prodaja, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    crypto_atms: {
      title: 'CRYPTOMONTE.ME - Crypto Automati',
      description: 'Saznajte više o mreži kripto automata u Srbiji. Takođe, možete saznati u kojim objektima možete usluge platiti kripto valutama. ',
      keywords: 'ATM, automati, mreža, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    news: {
      title: 'CRYPTOMONTE.ME - Vijesti',
      description: 'Saznajte više o aktuelnostima iz kripto svijeta. Pratite naša obaveštenja od značaja za korisnike. ',
      keywords: 'vijesti, obavjestenja, kripto vijesti, novosti, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    standing: {
      title: 'CRYPTOMONTE.ME - Zahtjev',
      description: '' ,
      keywords: 'podijnet, zahtjev, kupovina, prodaja, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    about: {
      title: 'CRYPTOMONTE.ME - O nama',
      description: 'Kriptovalutama se bavimo od 2012. godine. Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspješno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'O nama, tim, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    questionnaire: {
      title: 'CRYPTOMONTE.ME - Upitnik',
      description: '',
      keywords: ''
    },
    documentation: {
      title: 'CRYPTOMONTE.ME - Dokumentacija',
      description: '',
      keywords: ''
    },
    campaign: {
      title: 'CRYPTOMONTE.ME - Kripto Petak',
      description: '',
      keywords: ''
    },
    promotion: {
      title: 'ECD.rs - Promo Kod',
      description: '',
      keywords: ''
    },
    subscribe_unregistered: {
      title: 'CRYPTOMONTE.ME - Prijava na Newsletter',
      description: '',
      keywords: ''
    },
    wallet: {
      title: 'CRYPTOMONTE.ME - Wallet',
      description: '',
      keywords: ''
    },
    under: {
      title: 'CRYPTOMONTE.ME - Ažuriranje sajta',
      description: 'Sajt se trenutno održava',
      keywords: 'under construction, ažuriranje, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    verify: {
      title: 'CRYPTOMONTE.ME - Verifikacija naloga',
      description: 'CRYPTOMONTE.ME verify description',
      keywords: 'nalog, verify, verifikacija'
    },
    consultation: {
      title: 'CRYPTOMONTE.ME - Konsultacija',
      description: "Podržavamo i unapređujemo Vaša znanja edukacijom i konsaltingom",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    buy: {
      title: 'CRYPTOMONTE.ME - Kupovina',
      description: "Kupovina kriptovalute",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    sell: {
      title: 'CRYPTOMONTE.ME - Prodaja',
      description: "Prodaja kriptovalute",
      keywords: 'Prodaj, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    education: {
      title: 'CRYPTOMONTE.ME - Edukacija',
      description: "Edukacija",
      keywords: 'predavanje, nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    voucher: {
      title: 'CRYPTOMONTE.ME - Vaučer',
      description: "Edukacija",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka,Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    shop: {
      title: 'CRYPTOMONTE.ME - Prodavnica',
      description: "Prodavnica",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    pay2me: {
      title: 'CRYPTOMONTE.ME - Pay2Me',
      description: "Pay2Me",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    choose: {
      title: 'CRYPTOMONTE.ME - Stranica za izbor plaćanja',
      description: 'Izbor plaćanja',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    ecd_services: {
      title: 'CRYPTOMONTE.ME - Usluge koje nudimo',
      description: 'Crypto Monte usluge',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    hash_go: {
      title: 'CRYPTOMONTE.ME - HASH&GO',
      description: 'Saznajte više o tome u kojim objektima možete usluge platiti bitkoinom. ',
      keywords: 'ATM, automati, mreza, hash&go, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    consult: {
      title: 'CRYPTOMONTE.ME - Konsultacija',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom iz oblasti kriptovaluta i blockchaina",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    referrals: {
      title: 'CRYPTOMONTE.ME - Referal linkovi',
      description: "Crypto Monte usluge",
      keywords: 'zaradi, nalog, referali, referrals, link, register, earn, preporuci, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    guest: {
      title: 'CRYPTOMONTE.ME - Prodaja i kupovina kriptovaluta - Crna Gora.',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta u Crnoj Gori. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Crna Gora, Montenegro, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, cryptomonte, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    }
  },

  eu: {
    home: {
      title: 'ECD EU - Navigating The World of Crypto Since 2012',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    reset: {
      title: 'ECD EU - Change Password',
      description: 'ecd.rs home description',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    account: {
      title: 'ECD EU - Change Account',
      description: 'ecd.rs home description',
      keywords:'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    faq: {
      title: 'ECD EU - Frequently Asked Questions',
      description: 'Saznajte više, kako da pravilno koristite usluge koje su Vam omogućene, u sekciji "Često postavljena pitanja"',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, FAQ'
    },
    provisions: {
      title: 'ECD EU - Transaction Cost',
      description: 'Informišite se više o troškovima transakcija za kupovinu i prodaju kripto valuta na ECD.rs',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    contact: {
      title: 'ECD EU - Contact Us',
      description: 'Ukoliko želite da nas kontaktirate to možete učiniti putem sajta koristeći kontakt formu. Radno vreme korisničke podrške je od 9 do 21h.',
      keywords: 'kontakt, pitajte, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, Contact'
    },
    transactions: {
      title: 'ECD EU - Transactions',
      description: 'ecd.rs home description',
      keywords: 'transakcije, nalozi, kupovina, prodaja, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    crypto_atms: {
      title: 'ECD.rs - Crypto Automati',
      description: 'Saznajte više o mreži kripto automata u Srbiji. Takođe, možete saznati u kojim objektima možete usluge platiti kripto valutama. ',
      keywords: 'ATM, automati, mreža, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    news: {
      title: 'ECD EU - News',
      description: 'Saznajte više o aktuelnostima iz kripto sveta. Pratite naša obaveštenja od značaja za korisnike. ',
      keywords: 'vesti, obavestenja, kripto vesti, novosti, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    standing: {
      title: 'ECD EU - Request',
      description: '' ,
      keywords: 'podnet, zahtev, kupovina, prodaja, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    about: {
      title: 'ECD EU - About Us',
      description: 'Kriptovalutama se bavimo od 2012. godine. Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'O nama, tim, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    questionnaire: {
      title: 'ECD EU - Form',
      description: '',
      keywords: ''
    },
    promotion: {
      title: 'ECD EU - Promo Code',
      description: '',
      keywords: ''
    },
    campaign: {
      title: '',
      description: '',
      keywords: ''
    },
    subscribe_unregistered: {
      title: 'ECD EU - Subscribe to ECD Newsletter',
      description: '',
      keywords: ''
    },
    wallet: {
      title: 'ECD EU - Wallet',
      description: '',
      keywords: ''
    },
    documentation: {
      title: 'ECD EU - Documentation',
      description: '',
      keywords: ''
    },
    under: {
      title: 'ECD EU - Website Update',
      description: 'Sajt se trenutno održava',
      keywords: 'under construction, ažuriranje, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    verify: {
      title: 'ECD EU - Account Verification',
      description: 'ecd.rs verify description',
      keywords: 'nalog, verify, verifikacija'
    },
    consultation: {
      title: '',
      description: "",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    buy: {
      title: 'ECD EU - Buy',
      description: "Kupovina kriptovalute",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    sell: {
      title: 'ECD EU - Sell,',
      description: "Prodaja kriptovalute",
      keywords: 'Prodaj, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    education: {
      title: '',
      description: "Education",
      keywords: 'predavanje, nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    voucher: {
      title: 'ECD EU - Voucher',
      description: "Edukacija",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka,Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    shop: {
      title: '',
      description: "Shop",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    pay2me: {
      title: '',
      description: "Pay2Me",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    choose: {
      title: 'ECD EU - Payment Selection Page',
      description: 'Izbor plaćanja',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    ecd_services: {
      title: 'ECD EU - Our Services',
      description: 'ECD usluge',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    hash_go: {
      title: '',
      description: 'Saznajte više o tome u kojim objektima možete usluge platiti bitkoinom. ',
      keywords: 'ATM, automati, mreza, hash&go, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    consult: {
      title: '',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom iz oblasti kriptovaluta i blockchaina",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    referrals: {
      title: '',
      description: "ECD usluge",
      keywords: 'zaradi, nalog, referali, referrals, link, register, earn, preporuci, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    guest: {
      title: 'ECD EU - Navigating The World of Crypto Since 2012.',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    }
  },

  au: {
    home: {
      title: 'ECD Australia - Navigating The World of Crypto Since 2012',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    reset: {
      title: 'ECD Australia - Change Password',
      description: 'ecd.rs home description',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    account: {
      title: 'ECD Australia - Change Account',
      description: 'ecd.rs home description',
      keywords:'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    faq: {
      title: 'ECD Australia - Frequently Asked Questions',
      description: 'Saznajte više, kako da pravilno koristite usluge koje su Vam omogućene, u sekciji "Često postavljena pitanja"',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, FAQ'
    },
    provisions: {
      title: 'ECD Australia - Transaction Cost',
      description: 'Informišite se više o troškovima transakcija za kupovinu i prodaju kripto valuta na ECD.rs',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    contact: {
      title: 'ECD Australia - Contact Us',
      description: 'Ukoliko želite da nas kontaktirate to možete učiniti putem sajta koristeći kontakt formu. Radno vreme korisničke podrške je od 9 do 21h.',
      keywords: 'kontakt, pitajte, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, Contact'
    },
    transactions: {
      title: 'ECD Australia - Transactions',
      description: 'ecd.rs home description',
      keywords: 'transakcije, nalozi, kupovina, prodaja, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin, Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    crypto_atms: {
      title: 'ECD.rs - Crypto Automati',
      description: 'Saznajte više o mreži kripto automata u Srbiji. Takođe, možete saznati u kojim objektima možete usluge platiti kripto valutama. ',
      keywords: 'ATM, automati, mreža, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    news: {
      title: 'ECD Australia - News',
      description: 'Saznajte više o aktuelnostima iz kripto sveta. Pratite naša obaveštenja od značaja za korisnike. ',
      keywords: 'vesti, obavestenja, kripto vesti, novosti, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    standing: {
      title: 'ECD Australia - Request',
      description: '' ,
      keywords: 'podnet, zahtev, kupovina, prodaja, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    about: {
      title: 'ECD Australia - About Us',
      description: 'Kriptovalutama se bavimo od 2012. godine. Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'O nama, tim, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    questionnaire: {
      title: 'ECD Australia - Form',
      description: '',
      keywords: ''
    },
    documentation: {
      title: 'ECD.rs - Dokumentacija',
      description: '',
      keywords: ''
    },
    promotion: {
      title: 'ECD.rs - Promo Kod',
      description: '',
      keywords: ''
    },
    campaign: {
      title: '',
      description: '',
      keywords: ''
    },
    subscribe_unregistered: {
      title: 'ECD Australia - Subscribe to ECD Newsletter',
      description: '',
      keywords: ''
    },
    wallet: {
      title: 'ECD Australia - Wallet',
      description: '',
      keywords: ''
    },
    documentation: {
      title: 'ECD Australia - Documentation',
      description: '',
      keywords: ''
    },
    under: {
      title: 'ECD Australia - Website Update',
      description: 'Sajt se trenutno održava',
      keywords: 'under construction, ažuriranje, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    verify: {
      title: 'ECD Australia - Account Verification',
      description: 'ecd.rs verify description',
      keywords: 'nalog, verify, verifikacija'
    },
    consultation: {
      title: '',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    buy: {
      title: 'ECD Australia - Buy',
      description: "Kupovina kriptovalute",
      keywords: 'nalog, consulting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO, consulting, edukacija, konsultacija, obuka'
    },
    sell: {
      title: 'ECD Australia - Sell,',
      description: "Prodaja kriptovalute",
      keywords: 'Prodaj, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    education: {
      title: '',
      description: "Edukacija",
      keywords: 'predavanje, nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    voucher: {
      title: 'ECD Australia - Voucher',
      description: "Edukacija",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka,Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    shop: {
      title: '',
      description: "Prodavnica",
      keywords: 'nalog, consalting, prodavnica, nano ledger, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    pay2me: {
      title: '',
      description: "Pay2Me",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    choose: {
      title: 'ECD Australia - Payment Selection Page',
      description: 'Izbor plaćanja',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    ecd_services: {
      title: 'ECD Australia - Our Services',
      description: 'ECD usluge',
      keywords: 'placanja, transakcije, nalog, kupovina, prodaja, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'    },
    hash_go: {
      title: '',
      description: 'Saznajte više o tome u kojim objektima možete usluge platiti bitkoinom. ',
      keywords: 'ATM, automati, mreza, hash&go, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    consult: {
      title: '',
      description: "Podržavamo i unapređujemo vaša znanja edukacijom i konsaltingom iz oblasti kriptovaluta i blockchaina",
      keywords: 'nalog, consalting, edukacija, konsultacija, obuka, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    referrals: {
      title: '',
      description: "ECD usluge",
      keywords: 'zaradi, nalog, referali, referrals, link, register, earn, preporuci, Bitkoin, Ethereum, nalog, consalting, edukacija, konsultacija, obuka, Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    },
    guest: {
      title: 'ECD Australia - Navigating The World of Crypto Since 2012.',
      description: 'Prvi i najveći online servis za kupovinu i prodaju kriptovaluta. Uspešno posluje od 2012. godine. Putem ovog sajta lako i sigurno možete kupiti ili prodati Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC) i BitcoinCash (BCH).',
      keywords: 'Bitcoin, Srbija, Serbia, Bitkoin, Kriptovaluta, cryptocurrency, Kupi, buy, Prodaj, sell, Litecoin, Lajtkoin,Ethereum, Itirijum, ECD, BTC, LTC, ETH, blockchain, rudari, rudarenje, mining, koin, koini, token, ICO, STO'
    }
  }
}
// apiUrl: 'http://mapi-local.ecd.rs:8081/api/'

let customENV = 'dev';

export const CONFIG = configuration[ENV];
if(
  ENV === 'frnc_ecd_0_stage' ||
  ENV === 'ecd_cg'
  ){
  customENV = 'cg';
}

if(
  ENV === 'ecd_mk_stage' ||
  ENV === 'ecd_mk'
  ){
  customENV = 'mk';
}

if(
  ENV === 'ecd_eu_stage' ||
  ENV === 'ecd_eu'
  ){
  customENV = 'eu';
}

if(
  ENV === 'ecd_au_stage' ||
  ENV === 'ecd_au'
  ){
  customENV = 'au';
}

export const SEO = seo[customENV];
