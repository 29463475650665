import { __decorate } from "tslib";
import { EventEmitter, Injectable } from '@angular/core';
import { WebClientService } from './webclient-settings.service';
import { BehaviorSubject } from 'rxjs';
let LanguageService = class LanguageService {
    constructor(webSettingsService) {
        this.webSettingsService = webSettingsService;
        this.lang = '';
        this.lang$ = new BehaviorSubject(this.lang);
        this.languageFile = {};
        this.currentRoute = '/';
        this.spinnerOn = true;
        this.allLangs = [];
        this.webSettingsService.data.subscribe((data) => {
            this.languageFile = this.webSettingsService.getLanguageFile();
            this.lang = this.getCurrentLanguage();
            if (this.lang === undefined) {
                this.lang = localStorage.getItem('language');
            }
            this.lang$.next(this.lang);
            this.spinnerOn = false;
        });
        this.language = new EventEmitter();
    }
    languageChange(lang) {
        this.language.emit(lang);
    }
    switch_language(language) {
        localStorage.setItem('language', language);
        this.lang = language;
        this.languageChange(language);
        this.webSettingsService.getSettings(language).then((data) => {
            this.currentRoute = window.location.pathname;
            this.allLangs = this.webSettingsService.getAvailableLanguages();
            if (this.allLangs.length !== 0) {
                localStorage.setItem('all_langs', JSON.stringify(this.allLangs));
            }
            this.webSettingsService.redirectToHome(this.currentRoute);
            this.spinnerOn = false;
        });
    }
    getAvailableLanguages() {
        const languages = this.webSettingsService.getAvailableLanguages();
        return languages;
    }
    getCurrentLanguage() {
        const language = this.webSettingsService.getCurrentLanguage();
        return language;
    }
    getDefaultLanguage() {
        const language = this.webSettingsService.getDefaultLanguage();
        return language;
    }
    getChosenLanguage() {
        return this.lang$.asObservable();
    }
};
LanguageService.ctorParameters = () => [
    { type: WebClientService }
];
LanguageService = __decorate([
    Injectable()
], LanguageService);
export { LanguageService };
